<template>
  <div>
    <h3 class="title">
      {{ title }}
    </h3>
    <v-row>
      <v-col
        v-for="(widget, index) in content"
        :key="index"
        :md="3"
        :sm="6"
        :xs="12"
      >
        <Widget
          :title="widget.title"
          :icon="widget.icon"
          :status="widget.status"
          :color="widget.color"
          :type="type"
          :inspection-statuses="widget.inspectionStatuses"
          :is-engineer="widget.isEngineer"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import Widget from '@/components/Widgets/Widget';

  export default {
    name: 'Widgets',
    components: {
      Widget
    },
    props: {
      title: {
        type: String,
        required: true
      },
      type: {
        type: String,
        required: true
      },
      content: {
        type: Array,
        required: true
      }
    }
  };
</script>

<style lang="scss" scoped>
.title {
  font-weight: 400 !important;
}
</style>
