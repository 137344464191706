<template>
  <v-container grid-list-xl>
    <v-layout
      row
      wrap
    >
      <v-flex
        lg12
        sm12
        xs12
      >
        <v-alert
          border="left"
          color="blue-grey"
          dark
          text
        >
          <strong>{{ user.name }}</strong>, bem-vindo(a) ao sistema
          <strong>{{ appName }}</strong>.
        </v-alert>
        <v-divider class="mb-5" />
        <Widgets
          :title="'Pedidos'"
          :type="''"
          :content="verifyIsEngineer()"
        />
        <Widgets
          v-if="user.profile!=='ENGINEER'"
          :title="'Autovistoria + Laudo de Engenheiro Mecânico'"
          :type="''"
          :content="inspectionWidgets"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
  import GlobalStore from '@/store/modules/global';
  import { APP_NAME } from '@/commons/config';
  import Widgets from '@/components/Widgets/Widgets';

  export default {
    components: {
      Widgets
    },
    data: () => ({
      appName: APP_NAME,
      widgets: [
        {
          title: 'Aguardando Análise',
          icon: 'assignment_return',
          status: ['AGUARDANDO_ANALISE'],
          color: 'primary',
          inspectionStatuses: [],
          isEngineer: false

        },
        {
          title: 'Pagamento Pendente',
          icon: 'assignment',
          status: ['PAGAMENTO_PENDENTE'],
          color: 'success',
          inspectionStatuses: [],
          isEngineer: false

        },
        {
          title: 'Documentação Pendente',
          icon: 'assignment_late',
          status: ['DOCUMENTACAO_PENDENTE'],
          color: 'warning',
          inspectionStatuses: [],
          isEngineer: false

        },
        {
          title: 'Documentação Pendente ARCE',
          icon: 'assignment_returned',
          status: ['DOCUMENTACAO_PENDENTE_ARCE'],
          color: 'warning',
          inspectionStatuses: [],
          isEngineer: false
        },
        {
          title: 'Aguardando Análise do Engenheiro',
          icon: 'assignment_returned',
          status: [],
          color: 'warning',
          inspectionStatuses: ['1'],
          isEngineer: true

        },
        {
          title: 'Não aceito pela ARCE',
          icon: 'assignment_returned',
          status: ['NAO_ACEITO_PELA_ARCE'],
          color: 'warning',
          inspectionStatuses: [],
          isEngineer: false
        },
      ],
      inspectionWidgets: [
        {
          title: 'Em Análise Pelo Engenheiro',
          icon: 'assignment_returned',
          status: [],
          color: 'warning',
          inspectionStatuses: ['6'],
          isEngineer: false
        },
        {
          title: 'Aguardando Análise do Operador',
          icon: 'assignment_returned',
          status: [],
          color: 'warning',
          inspectionStatuses: ['4'],
          isEngineer: false
        },
      ]
    }),
    computed: {
      user: () => GlobalStore.user || {}
    },
    methods: {
      verifyIsEngineer() {
        const { functionalities } = this.user;
        const verifyEngineer = functionalities.includes('inspection_review')
        return this.widgets.filter(w => { return w.isEngineer == verifyEngineer})
      },
    }
  };
</script>

<style lang="scss" scoped>
.v-alert__content {
  font-size: 15px;
  strong {
    font-size: 15px !important;
  }
}
</style>
