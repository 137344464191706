<template>
  <router-link
    :to="{ name: 'dashboard-pedidos-list', query: { status: status.toString(), type, inspection_statuses: inspectionStatuses } }"
    :title="`Clique para ver os pedidos ${title}`"
  >
    <v-card class="app-card">
      <v-card-title class="app-card-title">
        {{ title }}
      </v-card-title>
      <v-card-text class="app-card-content">
        <span v-if="isLoading">Buscando informações...</span>
        <div v-else>
          <div class="widget-content">
            <div class="widget-content-left">
              <v-icon
                size="86px"
                :color="color"
              >
                {{ icon }}
              </v-icon>
            </div>
            <div class="widget-content-right">
              <h5
                v-if="error"
                class="text text-process red--text"
              >
                Erro ao busca informações
              </h5>
              <div v-else>
                <h2 class="text text-qtd">
                  {{ total ? total : 0 }}
                </h2>
                <h5 class="text text-process">
                  Pedidos
                </h5>
              </div>
            </div>
          </div>
        </div>
      </v-card-text>
      <div
        v-if="!isLoading"
        class="widget-content-updated"
      >
        <p>
          <v-icon size="15px">
            schedule
          </v-icon>
          <span>Atualizado em: {{ lastUpdate }}</span>
        </p>
      </div>
    </v-card>
  </router-link>
</template>

<script>
  import { mapActions, mapState } from 'vuex';
  import dayjs from 'dayjs';

  export default {
    name: 'Widget',
    props: {
      title: {
        type: String,
        required: true
      },
      status: {
        type: Array,
        required: true
      },
      icon: {
        type: String,
        required: true
      },
      color: {
        type: String,
        required: true
      },
      type: {
        type: String,
        required: true
      },
      inspectionStatuses:{
        type: Array,
        required: true
      },
      isEngineer: {
        type: Boolean,
        require: true
      }
    },
    data: () => ({
      total: null,
      lastUpdate: null,
      isLoading: false,
      error: false,
      timer: null
    }),
    computed: {
      ...mapState('pedido', ['table']),

    },
    mounted() {
      this.initData();
    },
    created() {
      this.watchChange();

    },
    destroyed() {
      this.clear();

    },
    beforeDestroy() {
      this.stopTimer();
    },
    methods: {
      async initData() {
        this.isLoading = true;
        this.error = false;
        try {
          this.total = await this.findByStatusAndType({
            status: this.status,
            tipoPedido: this.type,
            inspection_statuses: this.inspectionStatuses
          });
          this.isLoading = false;
        } catch (error) {
          this.error = true;
          this.isLoading = false;
        }
        this.lastUpdate = dayjs()
          .locale('pt-BR')
          .format('DD/MM/YYYY HH:mm:ss');
      },
      watchChange() {
        this.timer = setInterval(() => {
          this.initData();
        }, 30000);
      },
      stopTimer() {
        clearInterval(this.timer);
      },

      ...mapActions('pedido', { findByStatusAndType: 'FIND_BY_STATUS_AND_TYPE' }),
      ...mapActions('pedido', { clear: 'CLEAR' })
    },
  };
</script>

<style lang="scss" scoped>
.widget {
  &-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    &-left {
      .v-icon {
        margin-left: -15px;
      }
    }
    &-right {
      text-align: right;
      h2 {
        margin: 0px;
        font-size: 3.4rem !important;
        line-height: 54px !important;
      }
      h5 {
        margin: 0px;
      }
    }
    &-updated {
      margin: 0px 15px;
      border-top: solid 1px rgba(0, 0, 0, 0.1);
      padding: 5px 0px;
      color: #a2a2a2;
      .v-icon {
        margin-right: 3px;
      }
      p {
        margin: 0px;
        font-style: italic;
      }
      span {
        font-size: 11px;
        font-weight: 400;
      }
    }
  }
}
</style>
